import styles from "./am_navbar.module.scss";
import Image from "next/image";
import { AMSearchBox } from "@/src/common/ui/components/am_search_box/am_search_box";
import { useTranslation } from "next-i18next";
import * as React from "react";
import { AMCartButton } from "../am_cart_button/am_cart_button";
import { useAppDispatch, useAppSelector } from "@/src/common/ui/store";
import { clearSuggestions, getSuggestions, getSuggestionsResults, setMenuResultsOpen } from "@/src/products/ui/store/product_search.slice";
import { ChangeEvent, useCallback, useEffect, useRef } from "react";
import debounce from "lodash.debounce";
import { useRouter } from "next/router";
import { getFilteredProducts, getSearchValue, setSearchValue } from "@/src/products/ui/store/product_filters.slice";
import Link from "next/link";
import cs from "classnames";
import { getCartProductsAmount } from "@/src/cart/ui/store/products_cart.slice";
import TagManager from "react-gtm-module";

interface AMNavbarProps {
  hideSearchbar?: boolean;
  hideCart?: boolean;
}

export const AMNavbar = ({ hideSearchbar = false, hideCart = false }: AMNavbarProps) => {
  const { t } = useTranslation("navbar");
  const dispatch = useAppDispatch();
  const suggestionResults = useAppSelector(getSuggestionsResults);
  const cartProductsAmount = useAppSelector(getCartProductsAmount);
  const searchValue = useAppSelector(getSearchValue);
  const searchBoxInputRef = useRef<HTMLInputElement | null>(null);
  const router = useRouter();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearch = useCallback(
    debounce((e: ChangeEvent<HTMLInputElement>) => {
      dispatch(getSuggestions(e.target.value));
    }, 300),
    []
  );

  const handleSearch = (value: string) => {
    debouncedSearch.cancel();
    dispatch(setSearchValue(value.trim()));
    dispatch(clearSuggestions());
    if (router.pathname.includes("filter")) {
      dispatch(getFilteredProducts({ searchValue: value }));
    }
    router.push({ pathname: "/filter", query: { s: value } });
  };

  const handleCart = () => {
    TagManager.dataLayer({ dataLayer: { event: "gtm-click-cart" } });
    router.push("/cart");
  };

  useEffect(() => {
    if (!searchValue.length && searchBoxInputRef.current && searchBoxInputRef.current.value.length) {
      searchBoxInputRef.current.value = "";
    }
  }, [searchValue]);

  return (
    <div className={styles["am-navbar"]}>
      <div className={cs(styles["am-navbar__wrapper"], hideSearchbar && styles["am-navbar__wrapper--no-searchbar"])}>
        <Link href="/">
          <a className={styles["am-navbar__logo"]}>
            <Image src="/assets/images/logo_cabin_bag_finder.svg" priority layout="fill" alt="logo" />
          </a>
        </Link>
        {!hideSearchbar && (
          <AMSearchBox
            ref={searchBoxInputRef}
            className={styles["am-navbar__search-box"]}
            onChange={debouncedSearch}
            placeholder={t("search.placeholder")}
            searchResults={suggestionResults}
            onMenuChange={(menuState) => dispatch(setMenuResultsOpen(menuState === "open"))}
            onSearch={handleSearch}
            initialValue={searchValue.length ? searchValue : undefined}
          />
        )}
        {!hideCart && <AMCartButton amStyles="am-round" quantity={cartProductsAmount} onClick={handleCart} />}
      </div>
    </div>
  );
};
