import { WithChildren } from "@/src/common/ui/view_models/base_props";
import { AMFooter } from "@/src/common/ui/components/am_footer/am_footer";
import styles from "./am_base_layout.module.scss";
import cs from "classnames";
import { AMNavbar } from "@/src/common/ui/components/am_navbar/am_navbar";
import { useAppSelector } from "@/src/common/ui/store";
import { getIsMenuResultsOpen } from "@/src/products/ui/store/product_search.slice";
import { CSSTransition } from "react-transition-group";

export interface AMBaseLayoutProps extends WithChildren {
  mainBgColor?: "white" | "gray";
  hideSearchbar?: boolean;
  hideCart?: boolean;
}

export const AMBaseLayout = ({ children, mainBgColor = "gray", hideSearchbar = false, hideCart = false }: AMBaseLayoutProps) => {
  const isSearchMenuOpen = useAppSelector(getIsMenuResultsOpen);
  return (
    <div className={styles["am-base-layout"]}>
      <AMNavbar hideSearchbar={hideSearchbar} hideCart={hideCart} />
      {!hideSearchbar && (
        <CSSTransition in={isSearchMenuOpen} timeout={200} classNames="fade-in" mountOnEnter unmountOnExit>
          <div className={styles["search-overlay"]} />
        </CSSTransition>
      )}
      <main className={cs(styles["am-base-layout__main"], styles[`am-base-layout__main--bg-${mainBgColor}`])}>{children}</main>
      <AMFooter />
    </div>
  );
};
