import styles from "./am_quantity_notifier.module.scss";
import { MouseEventHandler } from "react";

interface AMQuantityNotifierProps {
  quantity: number;
  onClick?: MouseEventHandler;
}

export const AMQuantityNotifier = ({ quantity, onClick }: AMQuantityNotifierProps) => {
  return (
    <div onClick={onClick} className={styles["black-square"]}>
      <span>{quantity}</span>
    </div>
  );
};
