import styles from "./am_footer.module.scss";
import { useTranslation } from "next-i18next";
import Link from "next/link";
import { useMemo } from "react";
import { locator } from "@/ioc/index";
import { IEnvVars } from "@/src/common/domain/interfaces/env_vars";
import { TYPES } from "@/ioc/types";

export const AMFooter = () => {
  const { t } = useTranslation("footer");
  const envVars = useMemo(() => locator.get<IEnvVars>(TYPES.IEnvVars), []);

  return (
    <div className={styles["am-footer"]}>
      <div className={styles["am-footer__wrapper"]}>
        <Link href={`${envVars.amazonCountryDomain}${envVars.termAndConditionsLink}`} passHref>
          <a target="_blank">{t("termsAndConditions")}</a>
        </Link>
        <Link href={`${envVars.amazonCountryDomain}${envVars.privacyLink}`} passHref>
          <a target="_blank">{t("privacy")}</a>
        </Link>
        <Link href={`${envVars.amazonCountryDomain}${envVars.legalAreaLink}`} passHref>
          <a target="_blank">{t("legalArea")}</a>
        </Link>
        <Link href={`${envVars.amazonCountryDomain}${envVars.cookiesLink}`} passHref>
          <a target="_blank">{t("cookies")}</a>
        </Link>
        <Link href={`${envVars.amazonCountryDomain}${envVars.advertisementLink}`} passHref>
          <a target="_blank">{t("advertisement")}</a>
        </Link>
        <span className="body3">{t("trademark")}</span>
      </div>
    </div>
  );
};
