import { FormikHandlers, useField } from "formik";
import { FormikInputProps, AMFormikProps } from "../../view_models/formik";
import styles from "./am_input.module.scss";
import { FieldInputProps, FieldMetaProps } from "formik/dist/types";
import cs from "classnames";
import { FocusEventHandler, forwardRef, KeyboardEventHandler, ReactNode, Ref } from "react";

interface AMInputProps {
  id: string;
  label?: string;
  onChange?: FormikHandlers["handleChange"];
  onKeyPress?: KeyboardEventHandler<HTMLInputElement>;
  onFocus?: FocusEventHandler<HTMLInputElement>;
  onBlur?: FormikHandlers["handleBlur"];
  onKeyDown?: KeyboardEventHandler<HTMLInputElement>;
  placeholder?: string;
  formik?: AMFormikProps;
  name?: string;
  className?: string;
  icon?: ReactNode;
  value?: string;
}

// eslint-disable-next-line react/display-name
export const AMInput = forwardRef<HTMLInputElement, AMInputProps>(
  ({ value, label, onChange, onKeyPress, onFocus, onBlur, onKeyDown, placeholder, formik, name, id, className, icon }, ref) => {
    let field: FieldInputProps<any>;
    let meta: FieldMetaProps<any>;
    if (formik) {
      field = formik.field;
      meta = formik.meta;
    } else {
      const emptyHandler = () => {};
      field = {
        onBlur: onBlur || emptyHandler,
        value: value,
        onChange: onChange || emptyHandler,
        name: name || ""
      };
      meta = { initialTouched: false, initialValue: undefined, value: undefined, touched: false };
    }
    return (
      <>
        <label className={cs(styles["am-input"], icon && styles["am-input--has-icon"], className)} htmlFor={id}>
          {label && <span className={styles["am-input__label"]}>{label}</span>}
          <input ref={ref} id={id} {...field} placeholder={placeholder} onFocus={onFocus} onKeyDown={onKeyDown} onKeyPress={onKeyPress} />
          {icon}
        </label>
        {meta.error && meta.touched && <div className={styles["am-input-error"]}>{meta.error}</div>}
      </>
    );
  }
);

type AMInputFormikProps = FormikInputProps & Omit<AMInputProps, "formik">;

export const AMInputFormik = ({ id, label, name, onChange, placeholder, className }: AMInputFormikProps) => {
  const [field, meta] = useField(name);
  if (onChange) field.onChange = onChange;
  return <AMInput id={id} formik={{ field, meta }} label={label} placeholder={placeholder} className={className} />;
};
