import { MouseEventHandler, ReactNode } from "react";
import styles from "./am_button.module.scss";
import cs from "classnames";

export interface ButtonProps {
  onClick?: MouseEventHandler;
  type?: "submit" | "reset" | "button";
  disabled?: boolean;
  amStyles?: "am-orange" | "am-gray" | "am-gray-selected" | "am-filter" | "am-tag";
  icon?: ReactNode;
  iconPosition?: "left" | "right";
  children?: ReactNode | string;
  className?: string;
}

export const AMButton = ({ children, onClick, amStyles = "am-orange", type, disabled = false, icon, iconPosition, className }: ButtonProps) => {
  return (
    <button disabled={disabled} className={cs(className, styles[amStyles])} type={type} onClick={onClick}>
      {iconPosition === "left" && icon}
      {children}
      {iconPosition === "right" && icon}
    </button>
  );
};
