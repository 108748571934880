import styles from "./am_search_box.module.scss";
import { AMInput } from "@/src/common/ui/components/am_input/am_input";
import { Search } from "@/src/common/ui/icons";
import { AMIconButton } from "@/src/common/ui/components/am_icon_button/am_icon_button";
import cs from "classnames";
import { ControlledMenu, ControlledMenuProps, FocusableItem, MenuItem, MenuState } from "@szhsin/react-menu";
import { useEffect, useRef, useState, ChangeEvent, KeyboardEvent as SyntheticKeyboardEvent, useCallback, forwardRef, MutableRefObject } from "react";
import { useClickOutside } from "@/src/common/ui/hooks/mouse";
import { disableBodyScroll, clearAllBodyScrollLocks } from "body-scroll-lock";
import { setIn } from "formik";
import TagManager from "react-gtm-module";

interface AMSearchBoxProps {
  onChange?: (e: ChangeEvent<any>) => void;
  onMenuChange?: (menuState: MenuState) => void;
  onSearch?: (value: string) => void;
  initialValue?: string;
  placeholder?: string;
  className?: string;
  searchResults?: Array<string>;
}

// eslint-disable-next-line react/display-name
export const AMSearchBox = forwardRef<HTMLInputElement, AMSearchBoxProps>(
  ({ onChange, onMenuChange, onSearch, placeholder, className, searchResults = [], initialValue }, ref) => {
    const inputRef = useRef<HTMLInputElement | null>(null);
    const wrapperRef = useRef<HTMLDivElement>(null);
    const menuRef = useRef<HTMLElement>(null);
    const [menuState, setMenuState] = useState<MenuState>("closed");
    const [inputFocused, setInputFocus] = useState<boolean>(false);
    const [innerValue, setInnerValue] = useState<string>("");
    const [menuItemFocus, setMenuItemFocus] = useState<ControlledMenuProps["menuItemFocus"]>();
    const initialValueSet = useRef(false);

    useEffect(() => {
      if (!innerValue.length && initialValue?.length && !initialValueSet.current) {
        initialValueSet.current = true;
        setInnerValue(initialValue);
      }
    }, [initialValue, innerValue.length]);

    const setInputRef = (element: HTMLInputElement) => {
      inputRef.current = element;
      if (ref) {
        (ref as MutableRefObject<HTMLInputElement>).current = element;
      }
    };

    const closeMenu = useCallback(() => {
      if (menuState === "open") {
        setMenuState("closed");
        clearAllBodyScrollLocks();
        onMenuChange?.("closed");
      }
    }, [menuState, onMenuChange]);

    const onArrowDown = (e: SyntheticKeyboardEvent<HTMLInputElement>) => {
      if (e.key === "ArrowDown") {
        menuRef.current?.focus();
        setMenuItemFocus({ position: "first", alwaysUpdate: true });
      }
    };

    const onArrowUp = (e: SyntheticKeyboardEvent<HTMLDivElement>) => {
      if (e.key === "ArrowUp" && searchResults?.length && (e.target as HTMLDivElement).innerText === searchResults[0]) {
        setTimeout(() => {
          inputRef.current?.focus();
        }, 50);
      }
    };

    const selectItemResult = (resultItem: string) => {
      TagManager.dataLayer({ dataLayer: { term: resultItem, event: "gtm-click-search" } });
      onSearch?.(resultItem);
      setInnerValue(resultItem);
      if (inputRef.current) inputRef.current.value = resultItem;
      closeMenu();
    };

    const handleEnterKeyPress = (e: SyntheticKeyboardEvent<HTMLElement>, resultItem: string) => {
      if (e.key === "Enter" || e.keyCode === 13) {
        selectItemResult(resultItem);
      }
    };

    const handleMenuItemKeyPress = (e: SyntheticKeyboardEvent<HTMLElement>, resultItem: string) => {
      if (inputRef.current) inputRef.current.value = resultItem;
    };

    const _onChange = (e: ChangeEvent<HTMLInputElement>) => {
      setInnerValue(e.target.value);
      onChange?.(e);
    };

    useClickOutside(wrapperRef, closeMenu);

    useEffect(() => {
      if (searchResults?.length && inputFocused && menuState === "closed") {
        setMenuState("open");
        if (menuRef.current) disableBodyScroll(menuRef.current);
        onMenuChange?.("open");
      } else if (!searchResults?.length && menuState === "open") {
        closeMenu();
      }
    }, [closeMenu, inputFocused, menuState, onMenuChange, searchResults]);

    return (
      <div ref={wrapperRef} className={cs(styles["am-search-box"], className)}>
        <div className={styles["am-search-box__input-wrapper"]}>
          <AMInput
            ref={setInputRef}
            className={styles["am-search-box__input"]}
            onChange={_onChange}
            onFocus={() => setInputFocus(true)}
            onBlur={() => setInputFocus(false)}
            onKeyDown={onArrowDown}
            value={innerValue}
            onKeyPress={(e) => handleEnterKeyPress(e, inputRef.current?.value ?? "")}
            id="search-box"
            placeholder={placeholder}
          />
          <AMIconButton
            className={styles["am-search-box__button"]}
            icon={<Search />}
            amStyles="am-square"
            onClick={() => selectItemResult(inputRef.current?.value ?? "")}
          />
        </div>

        <ControlledMenu
          offsetY={8}
          ref={menuRef}
          className={styles["am-search-box__results-container"]}
          menuClassName={styles["am-search-box__results"]}
          state={menuState}
          overflow="hidden"
          anchorRef={inputRef}
          captureFocus={false}
          onKeyDown={onArrowUp}
          menuItemFocus={menuItemFocus}
        >
          {searchResults.map((result, idx) => (
            <MenuItem
              key={result}
              className={({ hover }) => cs(styles["am-search-box__results__item"], hover && styles["am-search-box__results__item--hover"])}
              onClick={() => selectItemResult(result)}
              onKeyUp={(e) => handleMenuItemKeyPress(e, result)}
              onKeyPress={(e) => handleEnterKeyPress(e, result)}
            >
              <p>
                {new RegExp(`^${inputRef.current?.value ?? ""}`).test(result) && <span className="body2">{inputRef.current!.value}</span>}
                <span className="body2--bold">{result.replace(new RegExp(`^${inputRef.current?.value ?? ""}`), "")}</span>
              </p>
            </MenuItem>
          ))}
        </ControlledMenu>
      </div>
    );
  }
);
