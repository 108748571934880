import type { MouseEventHandler } from "react";
import cs from "classnames";
import styles from "./am_cart_button.module.scss";
import { Cart } from "@/src/common/ui/icons";
import { AMIconButton } from "../am_icon_button/am_icon_button";
import { AMQuantityNotifier } from "../am_quantity_notifier/am_quantity_notifier";
export interface ButtonProps {
  onClick?: MouseEventHandler;
  disabled?: boolean;
  quantity: number;
  className?: string;
  amStyles: "am-square" | "am-round";
}

export const AMCartButton = ({ onClick, amStyles, disabled = false, className, quantity }: ButtonProps) => {
  return (
    <div className={cs(styles["cart-button"], className)}>
      <AMIconButton icon={<Cart />} amStyles={amStyles} disabled={disabled} onClick={onClick} />
      <AMQuantityNotifier onClick={onClick} quantity={quantity} />
    </div>
  );
};
